<template>
    <AdminToast />
   <add-invoice-form invoice-type='InputInvoice'></add-invoice-form>
</template>

<script>
import AdminToast from '@/components/shared/toast/adminToast.vue';
import AddInvoiceForm from '@/pages/invoice/addInvoiceForm.vue';

export default {
    components: { AddInvoiceForm, AdminToast },

};

</script>


<style scoped>

</style>
